export const periods = [
  {
    name: 'content.exportchart.trend.daily',
    value: 0
  },
  {
    name: 'content.exportchart.trend.weekly',
    value: 1
  },
  {
    name: 'content.exportchart.aquamoni.weekly',
    value: 2
  },
  {
    name: 'content.exportchart.aquamoni.monthly',
    value: 3
  },
  {
    name: 'content.exportchart.aquamoni.corporate',
    value: 4
  }
];
